import { Paper, PaperProps } from '@mui/material'
import React from 'react'
import {useTheme} from "@mui/material/styles";

const PaperBase = (props: PaperProps) => {
    const theme = useTheme();
    return (
        <Paper
            elevation={theme.elevation.general}
            sx={{ padding: theme.padding.large }}
            {...props}>
            {props.children}
        </Paper>
    )
}

export default PaperBase