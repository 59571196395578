import { Box, Divider, Grid, IconButton, Rating, Stack, Tab, Typography, useTheme } from "@mui/material";
import PaperBase from "../../components/Paper/PaperBase";
import { useEffect, useState } from "react";
import { getApp } from "../../api/AppApi";
import { AppModel } from "../../AutoGeneratedModels/appModel";
import AppleIcon from "@mui/icons-material/Apple";
import GoogleIcon from "@mui/icons-material/Google";
import ReactMarkdown from "react-markdown";
import ReviewsCard from "../../components/AppCards/ReviewsCard";
import { translate, useTranslations } from "@plsp/common";
import { AppDetailSkeleton } from "./AppDetailSkeleton";
import { TabContext, TabList, TabPanel } from '@mui/lab';

export const AppDetails = () => {
    const theme = useTheme();
    const [app, setApp] = useState<AppModel>();
    const translations = useTranslations();
    const [appLoading, setAppLoading] = useState<boolean>(true);
    const appSlug = new URLSearchParams(window.location.search).get("App");
    const [tabValue, setTabValue] = useState<string>("1");

    useEffect(() => {
        if (appSlug) {
            getApp(appSlug).then(response => {
                if (response) {
                    setApp(response);
                }
            }).finally(() => setAppLoading(false));
        }
    }, []);

    const setTotalReviewCount = (total: number, rating: number) => {
        if (app) {
            setApp({ ...app, totalReviews: total, rating: rating })
        }
    }

    const handleChange = (index: string) => {
        setTabValue(index);
    };


    return (
        <Grid container >
            {appLoading ?
                <AppDetailSkeleton />
                :
                <div>
                    {app ?
                        <PaperBase style={{ height: "100%", width: "100%" }}>
                            <Stack spacing={theme.space.general}>

                                <Stack direction='row' spacing={theme.space.small}>
                                    <img width={100} src={app.logo} alt='logo' style={{ borderRadius: theme.borderRadius.images }} />
                                    <Stack width='100%' >
                                        <Grid container direction='row'>
                                            <Grid item xs>
                                                <Typography variant='h5' fontWeight={theme.typography.fontWeightBold} noWrap>
                                                    {app.name}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs>
                                                <Grid container justifyContent='flex-end'>
                                                    {app.iosDownloadLink &&
                                                        <IconButton color="inherit" size="large" onClick={() => window.open(app.iosDownloadLink)}>
                                                            <AppleIcon />
                                                        </IconButton>
                                                    }
                                                    {app.androidDownloadLink &&
                                                        <IconButton color="inherit" size="large" onClick={() => window.open(app.androidDownloadLink)}>
                                                            <GoogleIcon />
                                                        </IconButton>
                                                    }
                                                </Grid>
                                            </Grid>


                                        </Grid>

                                        {app.publisher &&
                                            <Typography variant='subtitle1'>{app.publisher.name}</Typography>
                                        }
                                        <Stack direction='row' spacing={theme.space.small}>
                                            <Rating name="read-only" value={app.rating} readOnly precision={0.1} />
                                            {(app.totalReviews ?? 0) > 0 ?
                                                <>
                                                    <Typography variant='subtitle1'>{app.totalReviews}</Typography>
                                                    <Typography variant='subtitle1'>{translate("Afs.Reviews", translations)}</Typography>
                                                </>
                                                :
                                                <Typography variant='subtitle1'>{translate("Afs.NoReviews", translations)}</Typography>
                                            }

                                        </Stack>
                                    </Stack>
                                </Stack>

                                {app.pictures && app.pictures.some(x => x) &&
                                    <Stack direction='row' spacing={5} style={{ maxWidth: 910, overflow: 'auto' }} >
                                        {app.pictures.map((p, index) => {
                                            return (
                                                <Grid item key={index}>
                                                    <img width={150} src={p.valueOf()} alt="img" style={{ borderRadius: theme.borderRadius.images }} />
                                                </Grid>
                                            )
                                        })}
                                    </Stack>
                                }

                                {app.clinicDescription && app.description &&
                                    <Box sx={{ width: '100%' }}>
                                        <TabContext value={tabValue}>
                                            <Box >
                                                <TabList onChange={(e, value) => handleChange(value)}>
                                                    <Tab label={translate("Afs.ClinicDescription", translations)} value="1" />
                                                    <Tab label={translate("Afs.Description", translations)} value="2" />
                                                </TabList>
                                            </Box>
                                            <TabPanel value="1" >
                                                <Typography>
                                                    <ReactMarkdown>{app.clinicDescription}</ReactMarkdown>
                                                </Typography>
                                            </TabPanel>
                                            <TabPanel value="2"  >
                                                <Typography>
                                                    <ReactMarkdown>{app.description}</ReactMarkdown>
                                                </Typography>

                                            </TabPanel>

                                        </TabContext>
                                    </Box>
                                }

                                {(app.clinicDescription || app.description) && !(app.clinicDescription && app.description) &&
                                    <Typography>
                                        {app.clinicDescription &&
                                            <ReactMarkdown>{app.clinicDescription}</ReactMarkdown>
                                        }
                                        {app.description &&
                                            <ReactMarkdown>{app.description}</ReactMarkdown>
                                        }
                                    </Typography>
                                }

                                <Divider ></Divider>

                                <Typography variant="h5" fontWeight={theme.typography.fontWeightBold} marginTop={4} marginBottom={4}>{translate("Afs.ReviewApp", translations)}</Typography>


                                <ReviewsCard app={app} setTotalReviewCount={setTotalReviewCount} />

                                <Divider></Divider>

                                <Grid container direction='row'>

                                    <Grid container spacing={4} marginTop={2} marginBottom={2}>
                                        <Grid item xs={12}>
                                            <Typography variant="h6" fontWeight={theme.typography.fontWeightBold}>{translate("Afs.MoreInformation", translations)}</Typography>
                                        </Grid>

                                        {app.specialities && app.specialities.some(x => x) &&
                                            <Grid item xs={3}>
                                                <Grid container direction='column'>
                                                    <Typography variant="subtitle1" fontWeight={theme.typography.fontWeightBold}>{translate("Afs.Speciality", translations)}</Typography>
                                                    <Typography variant='subtitle2'>{app.specialities.join(', ')}</Typography>
                                                </Grid>
                                            </Grid>
                                        }

                                        {app.languages && app.languages.some(x => x) &&
                                            <Grid item xs={3}>
                                                <Grid container direction='column'>
                                                    <Typography variant="subtitle1" fontWeight={theme.typography.fontWeightBold}>{translate("Afs.Language", translations)}</Typography>
                                                    <Typography variant='subtitle2'>{(app.languages.map(x => translate(`Languages.${x}`, translations)).join(', '))}</Typography>
                                                </Grid>
                                            </Grid>
                                        }

                                        <Grid item xs={3}>
                                            <Grid container direction='column'>
                                                <Typography variant="subtitle1" fontWeight={theme.typography.fontWeightBold}>{translate("Afs.DataHosting", translations)}</Typography>
                                                <Typography variant="subtitle2">{app.dataHosting ? translate(`Countries.${app.dataHosting}`, translations) : ""}</Typography>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={3}>
                                            <Grid container direction='column'>
                                                <Typography variant="subtitle1" fontWeight={theme.typography.fontWeightBold}>{translate("Afs.Ownership", translations)}</Typography>
                                                <Typography
                                                    variant="subtitle2">{app.ownership ? translate(`Ownership.${app.ownership}`, translations) : ""}</Typography>
                                            </Grid>
                                        </Grid>

                                        {app.otherSpecialities && app.otherSpecialities.some(x => x) &&
                                            <Grid item xs={3}>
                                                <Grid container direction='column'>
                                                    <Typography variant="subtitle1" fontWeight={theme.typography.fontWeightBold}>{translate("Afs.OtherSpeciality", translations)}</Typography>
                                                    <Typography variant='subtitle2'>{app.otherSpecialities.join(', ')}</Typography>
                                                </Grid>
                                            </Grid>
                                        }


                                        {app.ce != undefined &&
                                            <Grid item xs={3}>
                                                <Grid container direction='column'>
                                                    <Typography variant="subtitle1" fontWeight={theme.typography.fontWeightBold}>{translate("Afs.CE", translations)}</Typography>
                                                    <Typography variant="subtitle2">{app.ce ? translate("Afs.CETrue", translations) : translate("Afs.CEFalse", translations)}</Typography>
                                                </Grid>
                                            </Grid>
                                        }

                                        <Grid item xs={3}>
                                            <Grid container direction='column'>
                                                <Typography variant="subtitle1" fontWeight={theme.typography.fontWeightBold}>{translate("Afs.ContentClassification", translations)}</Typography>
                                                <Typography variant="subtitle2">{app.pegiRating}</Typography>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={3}>
                                            <Grid container direction='column'>
                                                <Typography variant="subtitle1" fontWeight={theme.typography.fontWeightBold}>{translate("Afs.Website", translations)}</Typography>
                                                <Typography variant="subtitle2">{app.website}</Typography>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={3}>
                                            <Grid container direction='column'>
                                                <Typography variant="subtitle1" fontWeight={theme.typography.fontWeightBold}>{translate("Afs.Support", translations)}</Typography>
                                                <Typography variant="subtitle2">{app.supportEmail}</Typography>
                                                <Typography variant="subtitle2">{app.supportPhone}</Typography>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={3}>
                                            <Grid container direction='column'>
                                                <Typography variant="subtitle1" fontWeight={theme.typography.fontWeightBold}>{translate("Afs.GDPRPolitics", translations)}</Typography>
                                                <Typography variant="subtitle2">{app.gdprPolitics}</Typography>
                                            </Grid>
                                        </Grid>

                                        {app.publisher &&
                                            <Grid item xs={3}>
                                                <Grid container direction='column'>
                                                    <Typography variant="subtitle1" fontWeight={theme.typography.fontWeightBold}>{translate("Afs.Publisher", translations)}</Typography>
                                                    <Typography variant="subtitle2">{app.publisher.name}</Typography>
                                                    <Typography variant="subtitle2">{app.publisher.address}</Typography>
                                                    <Typography
                                                        variant="subtitle2">{app.publisher.postalCode + " " + app.publisher.city}</Typography>
                                                    <Typography variant="subtitle2">{app.publisher.website}</Typography>
                                                    <Typography variant="subtitle2">{app.publisher.email}</Typography>
                                                    <Typography variant="subtitle2">{app.publisher.phoneNumber}</Typography>
                                                </Grid>
                                            </Grid>
                                        }

                                    </Grid>
                                </Grid>
                            </Stack>

                        </PaperBase>
                        :
                        <div>{translate("Afs.NoAppFound", translations)}</div>
                    }
                </div>
            }
        </Grid>
    )
}