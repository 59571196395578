import { TotalRatingModel } from './../AutoGeneratedModels/totalRatingModel';
import { ErrorModel } from './../AutoGeneratedModels/errorModel';
import { get, post } from "@plsp/common";
import { AppListModel } from "../AutoGeneratedModels/appListModel";
import { AppModel } from "../AutoGeneratedModels/appModel";
import { CreateReviewRequest } from "../AutoGeneratedModels/createReviewRequest";
import { ReviewModel } from "../AutoGeneratedModels/reviewModel";

export async function getApps(): Promise<AppListModel[] | ErrorModel> {
    return await get<AppListModel[]>(`${process.env.REACT_APP_API}/api/v1/App/GetApps`);
}

export async function getApp(id: string): Promise<AppModel> {
    return await get<AppModel>(`${process.env.REACT_APP_API}/api/v1/App/GetApp?id=${id}`);
}

export async function createReview(request: CreateReviewRequest): Promise<TotalRatingModel | ErrorModel> {
    return await post<TotalRatingModel>(`${process.env.REACT_APP_API}/api/v1/Review/CreateReview`, request);
}

export async function getSpecialities(): Promise<string[] | ErrorModel> {
    return await get<string[]>(`${process.env.REACT_APP_API}/api/v1/Speciality`);
}

export async function getReviews(id: string, pageIndex: number | null, pageSize: number | null): Promise<ReviewResponse> {
    return await get<ReviewResponse>(`${process.env.REACT_APP_API}/api/v1/Review/GetReviews?appId=${id}&pageIndex=${pageIndex}&pageSize=${pageSize}`);
}

export interface ReviewResponse {
    reviewModels: ReviewModel[],
    hasMore: boolean,
    totalCount: number
}