import React, { useEffect, useState } from "react";
import { Avatar, Divider, Grid, Rating, Stack, styled, Typography, useTheme } from "@mui/material";
import { getApps } from "../../api/AppApi";
import { isErrorModel, translate, useTabNavigation, useTranslations } from "@plsp/common";
import { AppListModel } from "../../AutoGeneratedModels/appListModel";
import { appRoutes } from "../../index";
import PaperBase from "../../components/Paper/PaperBase";
import { stringAvatar } from "../../utils/AvatarHelpers";
import moment from "moment";
import { AppListSkeleton } from "./AppListSkeleton";
import { ErrorModel } from "../../AutoGeneratedModels/errorModel";
import { Sorting } from "../../models/Sorting";
import { AppCard } from "./AppCard";

export const AppList = (props: { sorting: Sorting }) => {
    const theme = useTheme();
    const [loading, setLoading] = useState<boolean>(true);
    const [apps, setApps] = useState<AppListModel[] | undefined>(undefined);
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined)
    const navigation = useTabNavigation();
    const translations = useTranslations();

    useEffect(() => {
        let ignore = false;

        if (!ignore) {
            setErrorMessage(undefined);
            getApps().then(result => {
                if (result) {
                    if (isErrorModel<ErrorModel>(result)) {
                        setErrorMessage(translate(`Afs.App.${result.errorCode}`, translations))

                    } else {
                        if (!result)
                            setErrorMessage(translate("Afs.App.NoApps", translations))
                        else {
                            if (props.sorting) {
                                sortList(result)
                            } else {
                                setApps(result);
                            }
                        }


                    }
                } else {
                    setErrorMessage(translate("Afs.App.NoApps", translations))
                }
            }).finally(() => setLoading(false));
        }

        return () => {
            ignore = true;
        }

    }, [])

    useEffect(() => {
        sortList(apps)
    }, [props.sorting])


    const sortList = (apps?: AppListModel[]) => {
        if (props.sorting && apps) {

            var sorted: AppListModel[] | undefined = undefined;
            if (props.sorting == Sorting.Popular)
                sorted = [...apps].sort((a, b) => b.totalReviews! - a.totalReviews!);
            else if (props.sorting == Sorting.New) {
                sorted = [...apps].sort((a, b) => b.createdDate!.getTime() - a.createdDate!.getTime());
            }

            if (sorted) {
                setApps(sorted)
            }
        }
    }


    const PaperBaseCursor = styled(PaperBase)({
        cursor: 'pointer',
        maxWidth: '450px',
        [theme.breakpoints.up('sm')]: {
            minWidth: '450px',
        }
    });


    return (
        <Grid container gap={theme.space.general} direction='row'>

            {loading &&
                <AppListSkeleton numberOfCards={4} />
            }

            {!loading && errorMessage &&
                <PaperBase >
                    <Typography>{errorMessage}</Typography>
                </PaperBase>
            }

            {!loading && apps && apps.some(x => x) &&
                apps.map((app, index) => (
                    <PaperBaseCursor onClick={() => navigation.push(`${appRoutes.Details}?App=${app.id}`)} key={index}>

                        <AppCard app={app} />

                        <Stack spacing={theme.space.small} >
                            {app.reviews &&
                                <Divider />
                            }

                            {app.reviews && app.reviews.map((review, index) => (
                                <Stack spacing={theme.space.small} key={index}>
                                    <Stack>
                                        <Grid container direction='row'>
                                            <Grid item xs >
                                                <Stack direction='row' spacing={theme.space.small}>
                                                    <Avatar {...stringAvatar(review)} />
                                                    <Stack>
                                                        <Typography variant='body2'>{review.employeeName}</Typography>
                                                        <Typography variant='caption'>{review.employeeTitle}</Typography>
                                                    </Stack>
                                                </Stack>
                                            </Grid>

                                            <Grid item xs >
                                                <Grid container justifyContent='flex-end'>
                                                    <Stack alignItems='flex-end'>
                                                        <Rating readOnly value={review.rating}></Rating>
                                                        <Typography variant='caption'>{moment(review.createdDateUTC).format('DD-MM-YYYY')}</Typography>
                                                    </Stack>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Typography sx={{
                                            overflow: 'hidden',
                                            display: '-webkit-box',
                                            WebkitLineClamp: '2',
                                            WebkitBoxOrient: 'vertical',
                                        }}
                                        >{review.comment}</Typography>
                                    </Stack>

                                    {(app.reviews?.length ? app.reviews?.length - 1 : 0) != index &&
                                        <Divider />
                                    }
                                </Stack>
                            ))}
                        </Stack>
                    </PaperBaseCursor>
                ))
            }
        </Grid >
    )
}