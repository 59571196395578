import { Modal, ModalProps } from '@mui/material'
import { Box, SxProps } from '@mui/system';
import React from 'react'
import { useTheme } from '@mui/material/styles';

const BaseModal = (props: ModalProps) => {
    const theme = useTheme();

    const style: SxProps = {
        position: 'absolute',
        top: '50%',
        left: `calc(50% + ${theme.leftMenu.width / 2}px)`,
        transform: 'translate(-50%, -50%)',
        bgcolor: '#fff',
        padding: '40px',
        border: 'rgb(235, 235, 235) solid 10px',
        borderRadius: "8px",
        p: 4,
    };

    return (
        <Modal
            {...props}>
            <Box sx={style}>
                {props.children}
            </Box>
        </Modal>
    )
}

export default BaseModal