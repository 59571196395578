import { Home, PostAdd, Vaccines, WbSunny, Whatshot } from '@mui/icons-material'
import { Divider, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Toolbar, Typography } from '@mui/material'
import { appRoutes } from '../../..'
import { translate, useTabNavigation, useTranslations } from '@plsp/common'
import { useTheme } from '@mui/material/styles';
import { SimpleMenuItem } from './SimpleMenuItem';


const LeftMenu = () => {
    const translations = useTranslations();
    const theme = useTheme();
    const nav = useTabNavigation();

    const appsMenuList: SimpleMenuItem[] =
        [
            {
                name: translate("Menu.Apps.New", translations),
                icon: <WbSunny />,
                link: appRoutes.News,
            },
            {
                name: translate("Menu.Apps.Popular", translations),
                icon: <Whatshot />,
                link: appRoutes.Popular,
            },
            {
                name: translate("Menu.Apps.Special", translations),
                icon: <Vaccines />,
                link: appRoutes.Special,
            }

        ];


    return (
        <Drawer variant='permanent' sx={{ width: theme.leftMenu.width }} PaperProps={{ sx: { width: theme.leftMenu.width } }}>
            <Toolbar />
            <List disablePadding>
                <ListItem disableGutters disablePadding>
                    <ListItemButton onClick={() => nav.push(appRoutes.Home)}>
                        <ListItemIcon>
                            <Home />
                        </ListItemIcon>
                        <ListItemText primary={translate("Menu.Home", translations)} />
                    </ListItemButton>
                </ListItem>
            </List>
            <Divider />
            <List disablePadding>
                {appsMenuList.map((menu, index) => (
                    <ListItem disableGutters disablePadding key={index}>
                        <ListItemButton onClick={() => nav.push(menu.link)}>
                            <ListItemIcon>
                                {menu.icon}
                            </ListItemIcon>
                            <ListItemText primary={menu.name} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
            <Divider />
        </Drawer>
    )
}

export default LeftMenu