import { createTheme } from '@mui/material';
import { grey } from '@mui/material/colors';
import { daDK } from '@mui/x-data-grid';
import componentStyleOverrides from './CompStyleOverride';

export const sizing = {
    leftMenuWidth: 240
}

const themeColors = {
    primary: '#558ABF',
    secondary: '#EFD6A8',
    success: '#44D600',
    warning: '#FFA319',
    error: '#FF1943',
    info: '#33C2FF',
    black: '#223354',
    white: '#ffffff',
    primaryAlt: '#000C57',

    textColorPrimary: '#2F3E43',
    menuSelected: '#42a5f5',
    menuSelectedBack: '#e3f2fd',
    background: '#f2f5f9'
}

export const PlspTheme = () => {

    const theme = createTheme({
        breakpoints: {
            values: {
                xs: 0,
                sm: 700,
                md: 900,
                lg: 1200,
                xl: 1536,
            }
        },
        space: {
            small: 2,
            general: 4,
        },
        padding: {
            extraSmall: 0.5,
            small: 1,
            general: 2,
            large: 4,
        },
        elevation: {
            extraSmall: 1,
            small: 2,
            general: 4,
            large: 8
        },
        borderRadius: {
            general: 2,
            images: 20
        },
        layout: {
            sidebar: {
                citizenBackGroundColor: themeColors.secondary
            }
        },
        leftMenu: {
            width: sizing.leftMenuWidth,
        },
        iconSize: {
            small: 12,
            general: 18,
            large: 24
        },

        palette: {
            primary: {
                main: themeColors.primary,
            },
            secondary: {
                main: themeColors.secondary,
            },
            background: {
                default: themeColors.background
            },
            text: {
                primary: themeColors.textColorPrimary
            }
        },
        typography: {
            fontFamily: `'Roboto', sans-serif`,
            fontWeightLight: 300,
            fontWeightRegular: 400,
            fontWeightMedium: 500
        }
    },
        daDK)

    theme.components = componentStyleOverrides(theme);

    return theme;
}; 
