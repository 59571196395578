import React, { useEffect } from "react";
import { useState } from "react";
import { AppBar, Box, Grid, Icon, IconButton, Menu, MenuItem, Stack, Toolbar, Typography } from "@mui/material";
import AccountCircle from '@mui/icons-material/AccountCircle';
import { Logout } from "@mui/icons-material";
import { UserModel } from "../../../AutoGeneratedModels/userModel";
import { useTranslations, translate, useTabNavigation } from "@plsp/common";
import WarningModal from "../../Modals/Warning/WarningModal";
import { removeAuthCookie } from "../../../api/TokenApi";
import { useTheme } from '@mui/material/styles';
import { appRoutes } from "../../..";

export const TopNavigation = (props: { user?: UserModel }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [loggingOut, setLoggingOut] = useState<boolean>(false);
    const [logOutFailed, setLogOutFailed] = useState<boolean>(false);
    const translations = useTranslations();
    const theme = useTheme();
    const nav = useTabNavigation();

    useEffect(() => {
        if (loggingOut) {
            removeAuthCookie().then(response => {
                response ? window.location.href = process.env.REACT_APP_AUTH_REDIRECT! : setLogOutFailed(true);
            }).finally(() => setLoggingOut(false))
        }
    }, [loggingOut])

    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    return (
        <AppBar position='fixed' elevation={0}>
            <Toolbar disableGutters sx={{ paddingLeft: 2, paddingRight: 2 }} >
                <Grid container direction="row" >
                    <Grid item xs={6}>
                        <IconButton onClick={() => nav.push(appRoutes.Home)} sx={{ borderRadius: 2, padding: 0 }}>
                            <Grid container alignItems='center' alignContent='center' justifyContent='flex-start' height='100%' >

                                <Stack direction="row" alignItems='center' alignContent='center' spacing={2} >
                                    <Box sx={{ height: "50px", width: "50px" }} component="img" src={`${process.env.REACT_APP_Blob}/afs/logos/appsforsundhed.png`} />
                                    <Typography color="#FFFF" variant="h5">{translate('Afs.Title', translations)}</Typography>
                                </Stack>
                            </Grid>
                        </IconButton>
                    </Grid>
                    <Grid item xs={6} >
                        <Grid container justifyContent='flex-end'>
                            <IconButton
                                onClick={handleMenu}
                                sx={{ borderRadius: 2, padding: 0 }}>
                                <Stack alignItems='end' justifyContent='center'  >
                                    <Typography variant='caption' color="#FFFF">
                                        {props.user?.username}
                                    </Typography>
                                    <Typography variant='caption' color="#FFFF">
                                        {props.user?.isAdmin ? 'Admin' : `${props.user?.clinic?.name}`}
                                    </Typography>
                                </Stack >
                                <AccountCircle sx={{ fontSize: 40, paddingLeft: theme.padding.general, color: '#FFF' }} />
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorEl}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={Boolean(anchorEl)}
                                onClose={() => setAnchorEl(null)}>
                                <MenuItem onClick={() => {
                                    setLoggingOut(true);
                                    setAnchorEl(null);
                                }}><Logout />{translate('Navigation.LogOut', translations)}</MenuItem>
                            </Menu>
                        </Grid>
                    </Grid>

                </Grid>

            </Toolbar>
            <WarningModal open={logOutFailed} setOpen={setLogOutFailed} warning text={translate('Navigation.LogOutFailed', translations)} />
        </AppBar >
    );
};
