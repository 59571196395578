import { Divider, Grid, Skeleton, Stack, styled, Typography, useTheme } from "@mui/material";
import PaperBase from "../../components/Paper/PaperBase";

export const AppListSkeleton = (props: { numberOfCards: number }) => {
    const theme = useTheme();

    const PaperBaseCursor = styled(PaperBase)({
        maxWidth: '450px',
        [theme.breakpoints.up('sm')]: {
            minWidth: '450px',
        }
    });


    return (
        <>
            {

                [...Array(props.numberOfCards ?? 3)].map((x, i) => {
                    {
                        return (
                            <PaperBaseCursor key={i} >
                                <Stack direction='row' spacing={theme.space.small} >
                                    <Grid container direction='row' columnSpacing={theme.space.small} rowSpacing={theme.space.small}>
                                        <Grid item>
                                            <Skeleton variant="rectangular" width={100} height={100} style={{ borderRadius: theme.borderRadius.images }} />
                                        </Grid>
                                        <Grid item xs >
                                            <Grid container>
                                                <Grid item>
                                                    <Stack spacing={theme.space.small}>
                                                        <Skeleton variant="text" width={120} />
                                                        <Skeleton variant="text" width={100} />
                                                        <Skeleton variant="text" width={170} />
                                                    </Stack>
                                                </Grid>
                                                <Grid item xs >
                                                    <Grid container direction='row-reverse' columnSpacing={theme.space.small}>
                                                        <Grid item>
                                                            <Skeleton variant="circular" width={40} height={40} />
                                                        </Grid>
                                                        <Grid item>
                                                            <Skeleton variant="circular" width={40} height={40} />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                        </Grid>
                                        <Grid item>
                                            <Stack>
                                                <Skeleton variant="text" width={120} />
                                                <Skeleton variant="text" width={250} />
                                                <Skeleton variant="text" width={200} />
                                                <Skeleton variant="text" width={150} />
                                            </Stack>
                                        </Grid>

                                        <Grid item width='100%' >
                                            <Stack spacing={theme.space.small}>
                                                <Divider />
                                                <Stack spacing={theme.space.small}>
                                                    {
                                                        [...Array(3)].map((x, i) => {
                                                            {
                                                                return (
                                                                    <Grid container direction='row' key={i}>
                                                                        <Grid item width='100%'>
                                                                            <Grid container columnSpacing={theme.space.small}>
                                                                                <Grid item>
                                                                                    <Skeleton variant="circular" width={40} height={40} />
                                                                                </Grid>
                                                                                <Grid item>
                                                                                    <Skeleton variant="text" width={140} />
                                                                                    <Skeleton variant="text" width={50} />
                                                                                </Grid>
                                                                                <Grid item xs>
                                                                                    <Grid container justifyContent='flex-end'>
                                                                                        <Stack alignItems='flex-end'  >
                                                                                            <Skeleton variant="text" width={140} />
                                                                                            <Skeleton variant="text" width={50} />
                                                                                        </Stack>

                                                                                    </Grid>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Grid>
                                                                        <Grid item>
                                                                            <Skeleton variant="text" width={350} />
                                                                            <Skeleton variant="text" width={140} />
                                                                        </Grid>
                                                                    </Grid>)
                                                            }
                                                        })
                                                    }
                                                </Stack>
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                </Stack>

                            </PaperBaseCursor>
                        )
                    }
                }
                )
            }
        </>
    )
}