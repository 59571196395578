import { Grid, Skeleton, Stack, Typography, useTheme } from "@mui/material";
import PaperBase from "../../components/Paper/PaperBase";

export const AppDetailSkeleton = () => {
    const theme = useTheme();

    return (
        <PaperBase style={{ height: "100%", width: "100%" }}>
            <Grid container marginBottom={5}>
                <Grid item marginRight={2}>
                    <Skeleton variant="rectangular" height={100} width={100} style={{ borderRadius: theme.borderRadius.images }} />
                </Grid>
                <Grid item xs>
                    <Stack>
                        <Grid container justifyContent='space-between'>
                            <Grid item>
                                <Typography variant="h3">
                                    <Skeleton width={300} />
                                </Typography>
                            </Grid>
                        </Grid>

                        <Typography variant="subtitle2"><Skeleton width={200} /></Typography>

                        <Grid container direction='row' spacing={2}>
                            <Grid item>
                                <Stack direction='row'>
                                    <Skeleton variant='circular' height={24} width={24}></Skeleton>
                                    <Skeleton variant='circular' height={24} width={24}></Skeleton>
                                    <Skeleton variant='circular' height={24} width={24}></Skeleton>
                                    <Skeleton variant='circular' height={24} width={24}></Skeleton>
                                    <Skeleton variant='circular' height={24} width={24}></Skeleton>
                                </Stack>
                            </Grid>
                            <Grid item>
                                <Typography variant="subtitle1"><Skeleton variant='text' width={150} /></Typography>
                            </Grid>
                        </Grid>
                    </Stack>
                </Grid>
            </Grid>

            <Stack direction='row' spacing={5} style={{ maxWidth: 910, overflow: 'auto' }} >

                {
                    [...Array(5)].map((x, i) => {
                        {
                            return (
                                <Grid item key={i}>
                                    <Skeleton variant="rectangular" height={300} width={150} style={{ borderRadius: theme.borderRadius.images }} />
                                </Grid>
                            )
                        }
                    })
                }
            </Stack>

            <Stack>
                <Typography style={{ height: "60vh" }} overflow='hidden'>
                    <br />
                    <Skeleton variant='text' width='256px' />
                    <Skeleton variant='text' width='910px' />
                    <Skeleton variant='text' width='910px' />
                    <Skeleton variant='text' width='850px' />
                    <br />
                    <Skeleton variant='text' width='910px' />
                    <Skeleton variant='text' width='910px' />
                    <Skeleton variant='text' width='750px' />
                </Typography>
            </Stack>
        </PaperBase>

    )
}