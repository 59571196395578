import { Stack, Grid, Typography, IconButton, Rating, useTheme } from "@mui/material"
import { translate, useTranslations } from "@plsp/common"
import { AppListModel } from "../../AutoGeneratedModels/appListModel";
import AppleIcon from "@mui/icons-material/Apple";
import GoogleIcon from "@mui/icons-material/Google";
import { TypographyLineClamp } from "@plsp/mui";


export const AppCard = (props: { app: AppListModel }) => {
    const theme = useTheme();
    const translations = useTranslations();

    const openLinkAction = (e: any, link?: string) => {
        e.stopPropagation();
        window.open(link);
    };


    return (
        <>
            <Stack direction='row' spacing={theme.space.small}>
                <img width={100} src={props.app.logo} alt='logo' style={{ borderRadius: theme.borderRadius.images }} />
                <Stack width='100%' >
                    <Grid container direction='row'>
                        <Grid item xs>

                            <Typography variant='h5' fontWeight={theme.typography.fontWeightBold}>
                                {props.app.name}
                            </Typography>
                        </Grid>
                        <Grid item xs>
                            <Grid container justifyContent='flex-end' direction='row'>
                                {props.app.iosDownloadLink &&
                                    <IconButton color="inherit" onClick={(e: any) => openLinkAction(e, props.app.iosDownloadLink)}>
                                        <AppleIcon />
                                    </IconButton>
                                }
                                {props.app.androidDownloadLink &&
                                    <IconButton color="inherit" onClick={(e: any) => openLinkAction(e, props.app.androidDownloadLink)}>
                                        <GoogleIcon />
                                    </IconButton>
                                }
                            </Grid>
                        </Grid>




                    </Grid>

                    {props.app.publisherName &&
                        <Typography variant='subtitle1'>{props.app.publisherName}</Typography>
                    }
                    <Stack direction='row' spacing={theme.space.small}>
                        <Rating name="read-only" value={props.app.rating} readOnly precision={0.1} />
                        {(props.app.totalReviews ?? 0) > 0 ?
                            <>
                                <Typography variant='subtitle1'>{props.app.totalReviews}</Typography>
                                <Typography variant='subtitle1'>{translate("Afs.Reviews", translations)}</Typography>
                            </>
                            :
                            <Typography variant='subtitle1'>{translate("Afs.NoReviews", translations)}</Typography>
                        }

                    </Stack>
                </Stack>
            </Stack>


            <Grid container spacing={2} marginTop={2} marginBottom={2}>
                <Grid item>
                    <Typography variant='body2'>{translate("Afs.Country", translations)}: {props.app.country ? translate(`Countries.${props.app.country}`, translations) : ""}</Typography>
                    {props.app.specialities && props.app.specialities.length > 0 &&
                        <TypographyLineClamp variant='body2'>{translate("Afs.Speciality", translations)}: {props.app.specialities.join(', ')}</TypographyLineClamp>
                    }
                    {props.app.otherSpecialities && props.app.otherSpecialities.length > 0 &&
                        <TypographyLineClamp variant='body2'>{translate("Afs.OtherSpeciality", translations)}: {props.app.otherSpecialities.join(', ')}</TypographyLineClamp>
                    }
                    {props.app.languages && props.app.languages.length > 0 &&
                        <TypographyLineClamp variant='body2'>{translate("Afs.Language", translations)}: {props.app.languages.map(x => translate(`Languages.${x}`, translations)).join(', ')}</TypographyLineClamp>
                    }
                    <Typography variant='body2'>{translate("Afs.DataHosting", translations)}: {props.app.dataHosting ? translate(`Countries.${props.app.dataHosting}`, translations) : ""}</Typography>
                </Grid>
            </Grid>

        </>
    )
}