import { Report } from '@mui/icons-material';
import { Button, Grid, Modal, Typography } from '@mui/material'
import React from 'react'
import { translate, useTranslations } from '@plsp/common';
import BaseModal from '../Base/BaseModal';

const WarningModal = (props: { open: boolean, setOpen: any, warning?: boolean, text?: string }) => {
    const translations = useTranslations();



    return (
        <BaseModal open={props.open}>
            <Grid container direction="column" gap={2}>
                <Grid container direction="column" gap={2} alignContent="center">
                    {props.warning &&
                        <Grid container direction="row" justifyContent="center" >
                            <Report color='error' />
                            <Typography color="red" variant='h3'>{translate("Admin.WarningModal.Warning", translations)}</Typography>

                        </Grid>}
                    {props.text ? <Typography align='center' variant='h4'>{props.text}</Typography> : null}
                </Grid>
                <Grid container direction="row" justifyContent="center">
                    <Button
                        color='inherit'
                        variant='contained'
                        onClick={() => props.setOpen('')}>
                        Ok
                    </Button>
                </Grid>
            </Grid>
        </BaseModal>
    )
}

export default WarningModal