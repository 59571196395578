import React, { useState, useEffect } from 'react';
import { Box, CssBaseline, ThemeProvider, Toolbar } from '@mui/material';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { getTranslations } from './api/TranslationApi';
import { Dictionary, Translation } from "./models/TranslationModels";
import { TopNavigation } from './components/Navigation/TopNavigation/TopNavigation';
import { UserModel } from "./AutoGeneratedModels/userModel";
import { getUserModel } from "./api/PermissionApi";
import { PlspTheme } from "./theme/PlspTheme";
import LeftMenu from "./components/Navigation/LeftNavigation/LeftMenu";
import { appRoutes } from ".";
import { AppList } from "./pages/Apps/AppList";
import { AppDetails } from "./pages/Apps/AppDetails";
import { translate, TranslationContext } from '@plsp/common';
import { Sorting } from './models/Sorting';
import { SpecialPage } from './pages/Apps/SpecialPage';

function App() {
    const theme = PlspTheme();
    const [translations, setTranslations] = useState<Dictionary<Translation>>(getInitialTranslationState());
    const [language] = useState("da-DK");
    const [isIE, setIsIE] = useState<boolean>(false);
    const [user, setUser] = useState<UserModel>();

    useEffect(() => {
        getUserModel().then(response => {
            if (response) {
                setUser(response as UserModel);
            }
        });
    }, [])

    useEffect(() => {
        document.title = translate("Afs.Title", translations)
    }, [translations]);

    useEffect(() => {
        //@ts-ignore
        if (window.document.documentMode) {
            setIsIE(true);
        }

        getTranslations(language).then(result => {
            if (result?.translations) {
                setTranslations(result.translations);
                localStorage.setItem("translations", JSON.stringify(result.translations));
            }
        })
    }, [language]);

    function getInitialTranslationState() {
        return JSON.parse(localStorage.getItem("translations") ?? "{}");
    }

    return (
        <TranslationContext.Provider value={translations}>
            <ThemeProvider theme={theme}>
                <CssBaseline>
                    <BrowserRouter>
                        <Box sx={{ display: 'flex' }} >
                            <TopNavigation user={user} />
                            <LeftMenu />
                            <Box padding={4}>
                                <Toolbar />
                                <Routes>
                                    <Route path={appRoutes.Home} element={<AppList sorting={Sorting.None} />} />
                                    <Route path={appRoutes.News} element={<AppList sorting={Sorting.New} />} />
                                    <Route path={appRoutes.Popular} element={<AppList sorting={Sorting.Popular} />} />
                                    <Route path={appRoutes.Special} element={<SpecialPage />} />
                                    <Route path={appRoutes.Details} element={<AppDetails />} />
                                </Routes>
                            </Box>
                        </Box>
                    </BrowserRouter>
                </CssBaseline>
            </ThemeProvider>
        </TranslationContext.Provider >
    );
}

export default App;
