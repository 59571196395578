import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import { AuthCheck } from '@plsp/mui'

export const appRoutes = {
  Home: "/",
  News: "/news",
  Popular: "/popular",
  Special: "/special",
  Details: "/details"
}

const root = createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <AuthCheck
      redirectUrl={`${process.env.REACT_APP_AUTH_REDIRECT}`}
      requestUrl={`${process.env.REACT_APP_API}/api/v1/permission/getlogincheck`}>
      <App />
    </AuthCheck>
  </React.StrictMode>
);