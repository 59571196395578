import { Components, Theme } from "@mui/material";

export default function componentStyleOverrides(theme: Theme): Components {
    return {
        MuiButton: {
            styleOverrides: {
                root: {
                    fontWeight: 500,
                    borderRadius: "5px",
                    minWidth: "100px"
                }
            }
        },
        MuiAppBar: {
            styleOverrides: {
                root: {
                    zIndex: theme.zIndex.drawer + 1
                }
            }
        },
        MuiBreadcrumbs: {
            styleOverrides: {
                li: {
                    paddingLeft: 0,
                    marginLeft: 0
                }
            }
        }
    };
}
