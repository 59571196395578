import { Avatar, Box, Button, Divider, Grid, Pagination, Rating, Skeleton, Stack, TextField, Typography, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { createReview, getReviews } from "../../api/AppApi";
import { ReviewModel } from "../../AutoGeneratedModels/reviewModel";
import Moment from "react-moment";
import { isErrorModel, translate, useTranslations } from "@plsp/common";
import { AppModel } from "../../AutoGeneratedModels/appModel";
import { CreateReviewRequest } from "../../AutoGeneratedModels/createReviewRequest";
import { stringAvatar } from "../../utils/AvatarHelpers";
import moment from "moment";
import { TotalRatingModel } from "../../AutoGeneratedModels/totalRatingModel";

const ReviewsCard = (props: { app: AppModel, setTotalReviewCount(total: number, rating: number): void }) => {
    const theme = useTheme();

    const REVIEWS_LENGTH_LIMIT = 2000;
    const [pageIndex, setPageIndex] = useState<number>(0);
    const [reviews, setReviews] = useState<ReviewModel[]>();
    const [appLoading, setAppLoading] = useState<boolean>(true);
    const translations = useTranslations();
    const rowsPerPage = 5;
    const [selectedRating, setSelectedRating] = useState<number | null>(null);
    const [reviewField, setReviewField] = useState<string>("");

    const changeReviewField = (event: React.ChangeEvent<HTMLInputElement>) => {
        setReviewField(event.target.value);
    };

    useEffect(() => {
        setup();
    }, [])

    useEffect(() => {
        setup();
    }, [pageIndex])


    function setup() {
        getReviews(props.app.id!, pageIndex, rowsPerPage).then(response => {
            if (response) {
                setReviews(response.reviewModels);
            }
        }).finally(() => setAppLoading(false));
    }

    const buttonClick = () => {
        if (props.app) {
            const request = {
                appId: props.app.id,
                rating: selectedRating,
                comment: reviewField,
            } as CreateReviewRequest

            createReview(request).then(response => {
                if (isErrorModel(response)) {
                    //todo handle error

                } else {
                    if (pageIndex == 0)
                        setup();
                    else
                        setPageIndex(0);

                    setReviewField("");
                    setSelectedRating(null);
                    props.setTotalReviewCount((response as TotalRatingModel).totalReviews ?? 0, (response as TotalRatingModel).rating ?? 0);
                }
            })
        }
    };

    const handlePaginationChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPageIndex(value - 1);
    };

    return (
        <Stack spacing={theme.space.general}>

            <Stack spacing={theme.space.general} >
                <Grid container>
                    <Grid xs item>
                        <Rating name="simple-controlled" value={selectedRating} precision={1}
                            onChange={(event, newValue) => {
                                setSelectedRating(newValue);
                            }} />
                    </Grid>



                    <Grid xs item>
                        <Box display="flex" justifyContent="flex-end">
                            <Button variant="contained" onClick={buttonClick} disabled={!(selectedRating && reviewField)}>
                                {translate("Afs.Save", translations)}
                            </Button>
                        </Box>
                    </Grid>


                </Grid>

                <TextField label={translate("Afs.AddComment", translations)} variant='outlined' fullWidth multiline
                    rows={2} value={reviewField} onChange={changeReviewField} inputProps={{ maxLength: REVIEWS_LENGTH_LIMIT }} helperText={`${reviewField.length}/${REVIEWS_LENGTH_LIMIT}`} />

                {reviews &&
                    <Divider />
                }

            </Stack>


            {
                appLoading ?
                    <Grid item xs={12} >
                        <Grid container padding={2} spacing={2}>
                            <Grid item xs={9}>
                                <Divider></Divider>
                                <Typography variant='subtitle1' marginTop={2}>
                                    <Skeleton></Skeleton>
                                    <Skeleton></Skeleton>
                                    <Skeleton></Skeleton>
                                </Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Grid container>
                                    <Grid item xs={10}>
                                        <Typography variant='h5'>
                                            <Skeleton></Skeleton>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Skeleton variant='circular' height={40} width={40}></Skeleton>
                                    </Grid>
                                </Grid>

                                <Stack spacing={1}>
                                    <Typography>
                                        <Skeleton></Skeleton>
                                    </Typography>
                                    <Typography>
                                        <Skeleton></Skeleton>
                                    </Typography>
                                    <Typography>
                                        <Skeleton></Skeleton>
                                    </Typography>

                                    <Stack direction='row' spacing={2} paddingTop={2}>
                                        <Stack direction='row'>
                                            <Skeleton variant='circular' height={24} width={24}></Skeleton>
                                            <Skeleton variant='circular' height={24} width={24}></Skeleton>
                                            <Skeleton variant='circular' height={24} width={24}></Skeleton>
                                            <Skeleton variant='circular' height={24} width={24}></Skeleton>
                                            <Skeleton variant='circular' height={24} width={24}></Skeleton>
                                        </Stack>
                                        <Skeleton width={100}></Skeleton>
                                    </Stack>
                                </Stack>
                            </Grid>
                        </Grid>
                    </Grid>
                    :
                    !reviews ?
                        <Typography>{translate("Afs.NoReviewsYet", translations)}</Typography>
                        :
                        reviews?.map((r, index) => (
                            <Stack key={index} spacing={theme.space.small}>

                                <Stack direction='row' alignItems='center' spacing={theme.space.small}>
                                    {r.employeeName && r.employeeTitle &&
                                        <Avatar {...stringAvatar(r)} />
                                    }

                                    <Stack>
                                        <Typography variant='subtitle1' fontWeight={theme.typography.fontWeightBold}>
                                            {r.employeeName}, {r.employeeTitle}
                                        </Typography>
                                        {r.clinicStreet2 ?
                                            <Typography variant="caption">
                                                {r.clinicName}, {r.clinicStreet1},  {r.clinicStreet2}
                                            </Typography>
                                            :
                                            <Typography variant="caption">
                                                {r.clinicName}, {r.clinicStreet1}
                                            </Typography>
                                        }
                                        <Typography variant="caption">
                                            {r.clinicTown} {r.clinicPostalCode}
                                        </Typography>
                                    </Stack>



                                </Stack>
                                <Grid container>
                                    <Grid item xs>
                                        <Rating readOnly value={r.rating}></Rating>

                                    </Grid>
                                    <Grid item xs >
                                        <Grid container justifyContent='flex-end'>
                                            <Typography variant='subtitle1'>{moment(r.createdDateUTC).format('DD-MM-YYYY')}</Typography>
                                        </Grid>
                                    </Grid>


                                </Grid>
                                <Typography variant='subtitle1'>{r.comment}</Typography>

                                {(reviews?.length ? reviews?.length - 1 : 0) != index &&
                                    <Divider />
                                }


                            </Stack>
                        ))
            }

            {
                Math.ceil((props.app.totalReviews ?? 0) / rowsPerPage) > 1 &&
                <Grid container justifyContent='center' padding={2}>
                    <Grid item>
                        <Pagination count={Math.ceil((props.app.totalReviews ?? 0) / rowsPerPage)} onChange={handlePaginationChange} />
                    </Grid>
                </Grid>
            }
        </Stack >
    )
}

export default ReviewsCard