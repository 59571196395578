import React, { useEffect, useState } from "react";
import { Grid, Stack, styled, Typography, useTheme } from "@mui/material";
import { getApps, getSpecialities } from "../../api/AppApi";
import { isErrorModel, translate, useTabNavigation, useTranslations } from "@plsp/common";
import { AppListModel } from "../../AutoGeneratedModels/appListModel";
import { appRoutes } from "../../index";
import PaperBase from "../../components/Paper/PaperBase";
import { AppListSkeleton } from "./AppListSkeleton";
import { ErrorModel } from "../../AutoGeneratedModels/errorModel";
import { AppCard } from "./AppCard";

export const SpecialPage = () => {
    const theme = useTheme();
    const [loading, setLoading] = useState<boolean>(true);
    const [apps, setApps] = useState<AppListModel[] | undefined>(undefined);
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined)
    const navigation = useTabNavigation();
    const translations = useTranslations();
    const [specialities, setSpecialities] = useState<string[]>([])

    const [mapped, setMapped] = useState<Map<string, AppListModel[]>>()


    useEffect(() => {
        let ignore = false;

        if (!ignore) {
            setErrorMessage(undefined);
            getApps().then(result => {
                if (result) {
                    if (isErrorModel<ErrorModel>(result)) {
                        setErrorMessage(translate(`Afs.App.${result.errorCode}`, translations))

                    } else {
                        if (!result)
                            setErrorMessage(translate("Afs.App.NoApps", translations))
                        else
                            setApps(result);

                    }
                } else {
                    setErrorMessage(translate("Afs.App.NoApps", translations))
                }
            }).finally(() => setLoading(false));
        }

        return () => {
            ignore = true;
        }

    }, [])

    useEffect(() => {
        getSpecialities().then(response => {
            if (isErrorModel(response)) {

            } else {
                setSpecialities(response)
            }
        })
    }, [])

    useEffect(() => {
        if (specialities && apps) {
            let mappe = new Map<string, AppListModel[]>();

            specialities.map(speciality => (
                mappe.set(speciality, apps.filter(x => x.specialities?.some(sp => sp == speciality)))
            ));

            setMapped(mappe)
        }

    }, [specialities, apps])




    const PaperBaseCursor = styled(PaperBase)({
        cursor: 'pointer',
        maxWidth: '450px',
        [theme.breakpoints.up('sm')]: {
            minWidth: '450px',
        }
    });



    return (
        <Grid container gap={theme.space.general} direction='column'>


            {loading &&
                <AppListSkeleton numberOfCards={4} />
            }

            {!loading && errorMessage &&
                <PaperBase >
                    <Typography>{errorMessage}</Typography>
                </PaperBase>
            }

            {!loading && mapped &&
                Array.from(mapped.entries()).map((key, index) => {


                    return (key[1] && key[1].length > 0 ?
                        <Stack key={index} spacing={theme.space.small}>
                            <Typography variant="h5" fontWeight={theme.typography.fontWeightBold}>{key[0]}</Typography>
                            <Stack direction='row' spacing={theme.space.general} key={index}>
                                {key[1] && key[1].length > 0 &&
                                    key[1].map((app, index) => {
                                        return (
                                            <PaperBaseCursor onClick={() => navigation.push(`${appRoutes.Details}?App=${app.id}`)} key={index}>
                                                <AppCard app={app} />
                                            </PaperBaseCursor>
                                        )
                                    })
                                }
                            </Stack>
                        </Stack>
                        :
                        <></>
                    )
                })
            }
        </Grid >
    )
}