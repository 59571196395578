import { appRoutes } from "../index";
import { Dispatch, SetStateAction } from "react";
import { ErrorModel } from "../AutoGeneratedModels/errorModel";

export function baseHandleError(r: Response) {
    if (!r.ok) {
        throw new Error(r.status + "|" + r.statusText)
    }
    return r;
}

function handle401(r: Response) {
    if (r.status === 401) {
        window.location.href = process.env.REACT_APP_AUTH_REDIRECT!
    }
    return r;
}

const isoRegexDate1 = /(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))/;
const isoRegexDate2 = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?)((-(\d{2}):(\d{2})|Z)?)$/;

const dateParser = function (key: string, value: any) {
    if (typeof value === 'string') {
        let result1 = isoRegexDate1.exec(value);
        if (result1)
            return new Date(value);

        let result2 = isoRegexDate2.exec(value);
        if (result2)
            return new Date(value);
    }
    return value;
};

const dateToDotNetParser = function (key: string, value: any) {
    if (typeof value === 'string') {
        let result1 = isoRegexDate1.exec(value);
        if (result1) {
            if (value.startsWith("0000")) {
                return new Date().toJSON();
            }
        }

        let result2 = isoRegexDate2.exec(value);
        if (result2) {
            if (value.startsWith("0000")) {
                return new Date().toJSON();
            }
        }
    }
    return value;
};


export async function get<T>(request: RequestInfo, handleError: (r: Response) => Response = baseHandleError): Promise<T | ErrorModel> {
    try {
        const response = await fetch(request, {
            headers: {},
            credentials: "include"
        })
            .then(handle401)
            .then(handleError)
            .then(r => r.text())
            .then(r => JSON.parse(r, dateParser))
            .catch(e => {
                console.log(e.message)
            });

        return await response;
    } catch (e) {
        console.log(e)
        const error: ErrorModel = {
            errorCode: 500,
            errorMessage: `${e}`
        };
        return error;
    }
}


export async function post<T>(url = '', data = {}): Promise<T | null> {
    return await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data, dateToDotNetParser),
        credentials: "include"
    })
        .then(baseHandleError)
        .then(handle401)
        .then(r => r.text())
        .then(r => JSON.parse(r, dateParser))
        .catch(e => {
            console.log(e)
        });
}

export async function postFile(url = '', data = {}): Promise<Blob | void> {
    return await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data, dateToDotNetParser),
        credentials: "include"
    })
        .then(baseHandleError)
        .then(handle401)
        .then(r => r.blob())
        .catch(e => {
            console.log(e)
        });
}

export async function put<T>(url = '', data = {}): Promise<T | null> {
    return await fetch(url, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
        credentials: "include"
    }).then(baseHandleError)
        .then(handle401)
        .then(r => r.text())
        .then(r => JSON.parse(r, dateParser))
        .catch(e => {
            console.log(e)
        });
}

export async function remove<T>(url = ''): Promise<T | null> {
    const response = await fetch(url, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: "include"
    })
        .then(baseHandleError)
        .then(handle401)
        .then(r => r.json())
        .catch(e => {
            console.log(e)
        });
    return await response;
}